import React, { Component } from 'react';

const { version } = require('../package.json');

class RootIndex extends Component {
  static getInitialProps = async (context) => {
    return {
      version,
    };
  };

  render() {
    return <div>{version}</div>;
  }
}

export default RootIndex;
